import { UserOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../constants/config';
import { authActions } from '../../store/auth';
import NavbarGroup from './NavbarGroup';
import NavbarItem from './NavbarItem';
import './_navbar.scss';

const Navbar: React.FC = () => {
  const dispatch = useDispatch(),
    logoutHandler = () => {
      dispatch(authActions.logout());
    },
    navigate = useNavigate(),
    name = useSelector((state: any) => state.auth.name),
    primaryColor = useSelector((state: any) => state.brokerconfig.primaryColor),
    controls = useSelector((state: any) => state.auth.controls),
    permissions = useSelector((state: any) => state.auth.permissions);


  return (
    <div className="navbar">
      {API_URL.includes('staging') && <Badge.Ribbon text="Staging" color={primaryColor} />}
      <div
        onClick={() => {
          navigate('/');
        }}
        className="logo"
      >
        {/* <img src={logo} alt="logo" /> */}
      </div>

      <div className="navbar-items">
        {permissions?.dashboard?.includes('view') && <NavbarItem to="/dashboard">Dashboard</NavbarItem>}
        {permissions?.client?.includes('view') && controls?.is_individual_section && (
          <NavbarGroup title="Client Manager" active>
            <NavbarItem group={true} to="/client-manager/corporate">
              Corporate
            </NavbarItem>
            <NavbarItem group={true} to="/client-manager/individual">
              Individual
            </NavbarItem>
          </NavbarGroup>
        )}
        {permissions?.client?.includes('view') && !controls?.is_individual_section && !controls?.is_admin && (
          <NavbarItem to="/client-manager/corporate">
            Corporate
          </NavbarItem>
        )}
        {/* <div className='separator'>Super admin controls</div> */}
        {permissions?.user_management?.includes('view') && controls?.is_admin && (<NavbarItem to="/agent-manager">Agent Manager</NavbarItem>)}
        {permissions?.products?.includes('view') && !controls?.is_admin && <NavbarItem to="/reports">Reports</NavbarItem>}
        {permissions?.products?.includes('view') && <NavbarItem to="/products">Products</NavbarItem>}
        {permissions?.products?.includes('view') && controls?.navnar_options?.offers_and_plans && <NavbarItem to="/offers-and-plans">Offers and Plans</NavbarItem>}
        {permissions?.partners?.includes('view') && controls?.is_partner_section && (<NavbarItem to="/offers">Offers</NavbarItem>)}
        {permissions?.claims?.includes('view') && !controls?.is_admin && <NavbarItem to="/claims-manager">Claims Manager</NavbarItem>}
        {permissions?.providers?.includes('view') && controls?.is_admin && <NavbarItem to="/providers">Providers</NavbarItem>}
        {permissions?.tpa_master?.includes('view') && controls?.is_admin && <NavbarItem to="/tpa-master">TPA Master</NavbarItem>}
        {permissions?.partners?.includes('view') && controls?.is_partner_section && <NavbarItem to="/partners">Partners</NavbarItem>}
        {permissions?.user_management?.includes('view') && (
          <NavbarItem to="/user-management">User Management</NavbarItem>
        )}
        {permissions?.client?.includes('view') && controls?.is_individual_section && controls?.is_whatsapp && <NavbarItem onClick={
          () => {
            window.open('https://app.clickup.com/37342017/v/li/169433970', '_blank');
          }
        }>Feedbacks</NavbarItem>}
      </div>

      <div className="navbar-item nav-link" onClick={logoutHandler}>
        Logout
      </div>

      <div className="nav-footer">
        {/* <div className='separator'></div> */}
        {/* <span className="version">
          Version 1.0.0
        </span> */}
        <span className="pp">
          <UserOutlined shape="round" />
        </span>
        <span className="username">{name}</span>
      </div>
    </div>
  );
};

export default Navbar;
