import { Col, Dropdown, Row, Space } from 'antd';
import EntityDetail from '../../../components/EntityDetail/EntityDetail';
import EntityOperations from '../../../components/EntityDetail/EntityOperations';
import Breadcrumbs, { ActiveBreadcrump } from '../../../components/header/Breadcrumbs';
import Tag from '../../../components/UI/Tags/Tag';
import {
  StarOutlined,
  StarFilled,
  DoubleRightOutlined,
  WarningOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Button } from '../../../components/UI/Button/Button';
import { outletContext } from './Claim';
import CustomModal from '../../../components/UI/Modals/CustomModal';
import { useLayoutEffect, useState } from 'react';
import api from '../../../api/api';
import { successNotification } from '../../../components/UI/Toast/Toast';
import { errorHandler, toTitleCase } from '../../../utils/helper';
import Loader from '../../../components/Loader/Loader';
import EntityListing from '../../../components/EntityListing/EntityListing';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import { renderStatus } from './ClaimsListing';
import { Tooltip } from 'antd';
import { dropdownArrow } from '../../../components/UI/Table/TableCTAComponent';
import TagMemberDrawer from './TagMemberDrawer';
import SyncClaims from './SyncClaims';
import EditRow from './EditRow';

const ViewClaim: React.FC = () => {
  const context = useOutletContext<outletContext>(),
    [loading, setLoading] = useState(false),
    [visible, setVisible] = useState(false),
    source = axios.CancelToken.source(),
    { id, claimid } = useParams(),
    history = createBrowserHistory({}),
    [claimsConfig, setClaimsConfig] = useState<any>(),
    navigate = useNavigate(),
    [deleteClaimConfirmation, setDeleteClaimConfirmation] = useState(false),
    [drawerVisible, setDrawerVisible] = useState<boolean>(false),
    routes = [
      {
        path: '/claims-manager/view-claim',
        props: { killBreadcrumb: true },
      },
      {
        path: 'claims-manager/view-claim/:claimid',
        breadcrumb: ActiveBreadcrump,
        props: {
          value: (
            <Row gutter={16} align="middle">
              <Col>{context?.claim?.claim_id}</Col>
              <Col>
                <EditOutlined onClick={() => setVisible(true)} className="edit-icon" />
              </Col>
              <Col>
                <Tag size="large" type={toTitleCase(context?.claim?.status)}>
                  {context?.claim?.status}
                </Tag>
              </Col>
            </Row>
          ),
        },
      },
      {
        path: 'client-manager/corporate/:id',
        props: { value: context?.claim?.organisation?.organisation_name },
      },
      {
        path: 'client-manager/corporate/:id/claims-manager/view-claim',
        props: { killBreadcrumb: true },
      },
      {
        path: 'client-manager/corporate/:id/claims-manager/view-claim/:claimid',
        breadcrumb: ActiveBreadcrump,
        props: {
          value: (
            <Row gutter={16} align="middle">
              <Col>{context?.claim?.claim_id}</Col>
              <Col>
                <EditOutlined onClick={() => setVisible(true)} className="edit-icon" />
              </Col>
              {context?.claim?.status && (
                <>
                  <Col>
                    <Tag size="large" type={toTitleCase(context?.claim?.status)}>
                      {context?.claim?.status}
                    </Tag>
                  </Col>
                  {context?.claim?.additional_details?.auto_generated ? (
                    <Col>
                      {!context?.claim?.additional_details?.member_found ? (
                        <Tooltip title="The claim is imported from insurer/TPA's database. We couldn't identify the member details">
                          <WarningOutlined style={{ color: 'red' }} />
                        </Tooltip>
                      ) : (
                        <Tooltip title="This claim was not found in your database, it is imported from insurer/TPA's databse">
                          <ExclamationCircleOutlined style={{ color: 'red' }} />
                        </Tooltip>
                      )}
                    </Col>
                  ) : (
                    context?.claim?.is_sync_with_processor && (
                      <Col>
                        <Tooltip title="This claim is in sync with the insurer/TPA database. Some details will be updated automatically">
                          <CheckCircleOutlined style={{ color: 'green' }} />
                        </Tooltip>
                      </Col>
                    )
                  )}
                </>
              )}
            </Row>
          ),
        },
      },
    ],
    columns = [
      {
        title: 'Claim ID',
        dataIndex: 'claim_id',
        key: 'claim_id',
        render: (value: string) => {
          return value || '-';
        },
      },
      {
        title: 'Sub type',
        dataIndex: 'sub_type',
        key: 'sub_type',
        render: (value: string) => {
          return toTitleCase(value) || '-';
        },
      },
      {
        title: 'Processed By',
        dataIndex: 'processed_by',
        key: 'processed_by',
        render: (value: string) => {
          return value || '-';
        },
      },
      {
        title: 'Claims Manager',
        dataIndex: ['claims_manager', 'name'],
        key: ['claims_manager', 'name'],
        render: (value: string) => {
          return value || '-';
        },
      },
      {
        title: 'TPA',
        dataIndex: ['tpa', 'name'],
        key: 'tpa',
        render: (value: string) => {
          return value || '-';
        },
      },
      {
        title: 'Claim Type',
        dataIndex: 'type',
        key: 'type',
        render: (value: string) => {
          return toTitleCase(value) || '-';
        },
      },
      {
        title: 'Claim Amount',
        dataIndex: 'claimed_amount',
        key: 'claimed_amount',
        render: (value: string) => {
          return value ? '₹ ' + value : '-';
        },
      },
      {
        title: 'Settled Amount',
        dataIndex: 'settled_amount',
        key: 'settled_amount',
        render: (value: string) => {
          return value ? '₹ ' + value : '-';
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (value: string) => {
          return renderStatus(toTitleCase(value));
        },
      },
      {
        title: '',
        dataIndex: 'view',
        fixed: 'right',
        render: (text: any, record: any) => {
          return (
            <span
              className="cta"
              onClick={(e) =>
                navigate(`/claims-manager/view-claim/${record?.id}`, {
                  state: {
                    data: record,
                  },
                })
              }
            >
              <DoubleRightOutlined />
            </span>
          );
        },
      },
    ],
    operationMenu = [
      {
        key: '1',
        value: 'treatment_details',
        notification: !context?.claim?.is_treatment_details_filled,
      },
      {
        key: '2',
        value: 'claim_details',
        notification: false,
      },
      {
        key: '3',
        value: 'documents',
        notification: !context?.claim?.are_all_documents_uploaded,
      },
      {
        key: '4',
        value: 'communication',
        notification: !context?.claim?.are_all_communication_sent,
      },
    ],
    markAsImportant = () => {
      context?.updateClaim(
        claimid,
        { marked_important: context.claim?.marked_important ? false : true },
        'Claim successfully marked as important'
      );
    },
    openClaimSubtype = (claimSubtype: any) => {
      navigate(id ? `/claims-manager/${id}/add-claim` : `/claims-manager/add-claim`, {
        state: {
          data: context?.claim,
          showToggle: false,
          parent_claims_id: claimid,
          type: 'CORPORATE',
          sub_type: claimSubtype,
        },
      });
    },
    deleteClaim = () => {
      if (claimid) {
        api.claims_management
          .deleteClaim(claimid, { is_active: false })
          .then((res) => {
            successNotification(res?.message);
            navigate(id ? `/client-manager/corporate/${id}/claims-manager` : '/claims-manager');
          })
          .catch((err) => {
            errorHandler(err?.response?.data);
          });
        setDeleteClaimConfirmation(false);
      }
    },
    downloadECard = () => {
      api.member_management
        .requestMemberEcard({
          member_id: context?.claim?.member_id,
          policy_id: context?.claim?.policy_id,
        })
        .then((res) => {
          successNotification('Health card is being downloaded');
          history.push(res?.data);
        })
        .catch((err) => {
          errorHandler(err?.response?.data);
        });
    },
    groupOverlay = () => {
      return (
        <div className="overflow-menu">
          {(context?.claim?.status === 'REJECTED' || context?.claim?.status === 'WITHDRAWN') && (
            <div className="item">
              <Tooltip title="If this unsettled claim is reopened, a new claim will be created, duplicating the relevant details from this claim">
                <div onClick={() => openClaimSubtype('REOPEN')}>Reopen Claim</div>
              </Tooltip>
            </div>
          )}
          {context?.claim?.claimed_amount !== context?.claim?.settled_amount && context?.claim?.status === 'SETTLED' && (
            <div className="item">
              <Tooltip title="If this claim settled for partial amount is reconsidered, a new claim duplicating the relevant details from this claim will be created">
                <div onClick={() => openClaimSubtype('RECONSIDERATION')}>Reconsider Claim</div>
              </Tooltip>
            </div>
          )}
          {!context?.claim?.parent_claims && (
            <div className="item">
              <Tooltip title="Add a pre-post hospitalization claim linked to this claim with all relevant details prefilled.">
                <div onClick={() => openClaimSubtype('PREPOST')}>Add PPH</div>
              </Tooltip>
            </div>
          )}
          <div className="item">
            <div
              onClick={() => {
                downloadECard();
              }}
            >
              Download Health Card
            </div>
          </div>
          <div className="item">
            <div
              onClick={() => {
                setDeleteClaimConfirmation(true);
              }}
            >
              Delete Claim
            </div>
          </div>
          {id &&
            context?.claim?.additional_details?.auto_generated &&
            !context?.claim?.additional_details?.member_found && (
              <div className="item">
                <div
                  onClick={() => {
                    setDrawerVisible(true);
                  }}
                >
                  Tag Member
                </div>
              </div>
            )}
        </div>
      );
    },
    getClaimsConfig = () => {
      api.claims_management
        .claimConfig({
          organisation_id: id,
        })
        .then((res: any) => {
          setClaimsConfig(res?.data);
        })
        .catch((err: any) => {
          errorHandler(err?.res?.data);
        });
    };

  useLayoutEffect(() => {
    if (claimid && id) {
      getClaimsConfig();
    }
  }, [claimid, id]);

  return (
    <Loader loading={loading}>
      {drawerVisible && (
        <TagMemberDrawer
          updateClaim={context?.updateClaim}
          claim={context?.claim}
          visible={drawerVisible}
          onClose={() => {
            setDrawerVisible(false);
          }}
        />
      )}
      {visible && (
        <EditRow
          onClose={() => setVisible(false)}
          visible={visible}
          initialValue={context?.claim}
          updateHandler={context?.updateClaim}
        />
      )}
      <CustomModal
        isModalVisible={deleteClaimConfirmation}
        confirmHandler={() => deleteClaim()}
        cancelHandler={() => setDeleteClaimConfirmation(false)}
        type="generic-confirmaion"
        modalTitle="Confirm deletion"
        data={{ message: `Are you sure you want to delete this claim record?` }}
      />
      <Row align="bottom" justify="space-between">
        <Col>
          <Breadcrumbs routes={routes} />
        </Col>
        <Col>
          <Row justify="end" align="middle" gutter={16}>
            {context?.claim?.sync_status && (
              <Col>
                <SyncClaims
                  modified_at={claimsConfig?.modified_at}
                  sync_status={claimsConfig?.api_sync}
                  policyNumberPresent={claimsConfig?.policy_number_added}
                  icon={true}
                  onResponse={context?.getClaim}
                  payload={{ policy_id: context?.claim?.policy_id }}
                />
              </Col>
            )}
            <Col>
              <div className="icon-boxed cta" onClick={markAsImportant}>
                {context?.claim?.marked_important ? (
                  <StarFilled style={{ fontSize: '22px' }} />
                ) : (
                  <StarOutlined style={{ fontSize: '22px' }} />
                )}
              </div>
            </Col>
            <span className="cta-divider"></span>
            <Col>
              <Dropdown overlay={groupOverlay} trigger={['click', 'hover']}>
                <Button size={'large'} type={'secondary'}>
                  <Space>
                    Actions
                    {dropdownArrow}
                  </Space>
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>

      <EntityDetail
        keysToDisplay={[
          [
            { key: 'organisation.organisation_name', value: 'Client Name' },
            { key: 'product.name', value: 'Product' },
            { value: 'Provider', key: 'provider_name' },
            { value: 'Policy Number', key: 'policy' },
            { value: 'TPA', key: 'tpa.name' },
            { value: 'Claim added on', key: 'created_at', type: 'date' },
          ],
          [
            { key: 'full_name', value: 'Primary Member' },
            { key: 'raised_for.name', value: 'Raised For' },
            { key: 'employee_id', value: 'Employee ID' },
            { key: 'health_id', value: 'Health ID' },
            { key: 'sum_insured_amount', value: 'Sum Insured' },
            { value: 'Network Hospitals', key: 'network_hospital_link', type: 'link' },
          ],
          [
            { key: 'claims_manager.name', value: 'Claims Manager' },
            { key: 'raised_for.date_of_birth', value: 'Date of Birth (Raised for)' },
            { key: 'user.email', value: 'Work Email' },
            { key: 'user.phone', value: 'Phone Number' },
            { key: 'user.personal_email', value: 'Personal Email' },
            { key: 'user.personal_phone', value: 'Personal Phone' },
          ],
        ]}
        data={context.claim}
        route={routes}
      />

      {context?.claim?.child_claim && (
        <>
          <div className="title">Sub-Claims</div>
          <EntityListing
            hideBreadcrumb={true}
            type="claims"
            header={columns}
            hideTableCTA={true}
            hasPaging={false}
            tableDataApi={() =>
              api.claims_management.getClaimsList(
                {
                  parent_claims_id: claimid,
                },
                source?.token
              )
            }
          />
        </>
      )}

      <EntityOperations menu={operationMenu} />
    </Loader>
  );
};

export default ViewClaim;
