import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TabButton } from '../UI/Button/Button';
import TableComponent from '../UI/Table/TableComponent';
import Breadcrumbs from '../header/Breadcrumbs';
import TableCTA from './TableCTA';
import { nullIfEmpty } from '../../utils/helper';

interface Props {
  routes?: any;
  getParams?: any;
  checkList?: any;
  params?: any;
  resetParams?: any;
  data?: any;
  dataSource?: any;
  count?: any;
  btnLoading?: any;
  header?: any;
  currentPageHandler?: any;
  getCheckList?: any;
  urlOffset?: any;
  urlLimit?: any;
  tableLoading?: any;
  searchKey?: any;
  type?: any;
  filter?: any;
  sort?: any;
  cta?: any;
  setParams?: any;
  tableDataApi?: any;
  tabs?: any;
  title?: any;
  disableSearch?: any;
  hasPaging?: any;
  editPermission?: boolean;
  hideBreadcrumb?: boolean;
  hideTableCTA?: boolean;
  searchPlaceholder?: string;
  setParentCount?: any;
  extraProp?: any;
}

const EntityListing: React.FC<Props> = (props) => {
  const getParams = (param: any) => {
      props?.setParams((prevParams: any) => nullIfEmpty({ ...prevParams, ...param }, true));
    },
    resetParams = () => {
      props?.setParams((prev: any) => ({ offset: prev.offset, limit: prev.limit }));
    },
    currentPageHandler = (page: object) => {
      props?.setParams((prev: any) => {
        return { ...prev, ...page };
      });
    },
    [tableLoading, setTableLoading] = useState(true),
    [data, setData] = useState([] as any),
    [website, setWebsite] = React.useState(null),
    [count, setCount] = useState<number | undefined>(undefined),
    getTableData = (params: any, source: any) => {
      setTableLoading(true);
      props
        ?.tableDataApi(params, source.token)
        .then((res: any) => {
          if (props?.type === 'product') {
            setData(res.data?.policies.concat(res.data?.subscriptions).concat(res?.data?.retails));
            setCount(res.data?.policies?.length + res.data?.subscriptions?.length + res.data?.retails?.length);
          } else if (props?.type === 'products') {
            setData(res?.data?.products);
            setCount(res?.data?.products?.length);
            setWebsite(res?.data?.website);
          } else if (props?.type === 'providerProducts') {
            setData(res?.data?.products);
            setCount(res?.data?.products?.length);
            setWebsite(res?.data?.website);
          } else if (props?.type === 'providerOffers') {
            setData(res?.data?.offers);
            setCount(res?.data?.offers?.length);
            setWebsite(res?.data?.website);
          } else if (
            props?.type === 'roles' ||
            props?.type === 'TPA' ||
            props?.type === 'communication' ||
            props?.type === 'benefits'
          ) {
            setData(res?.data);
            setCount(res?.data?.length);
          } else {
            setData(res?.data?.results);
            setCount(res?.data?.count);
          }
          setTableLoading(false);
        })
        .catch((e: any) => {
          setTableLoading(false);
          if (axios.isCancel(e)) {
            // console.log(`request cancelled:${e.message}`);
          } else {
            // console.log('another error happened:' + e.message);
          }
        });
    };

  useEffect(() => {
    let source = axios.CancelToken.source();
    getTableData(props?.params, source);
    return () => {
      source.cancel('Cancelling in cleanup');
    };
  }, [props?.params]);

  useEffect(() => {
    if (props?.setParentCount) {
      props?.setParentCount(count);
    }
  }, [count]);

  useEffect(() => {
    if (props?.type === 'providerOffers' || props?.type === 'providerProducts') {
      getTableData(props?.params, axios.CancelToken.source());
    }
  }, [props?.type]);

  return (
    <div className="client-list">
      {!props?.hideBreadcrumb && <Breadcrumbs routes={props.routes} />}

      {props?.extraProp}

      {props?.tabs && (
        <div className="table-cta-tabs">
          {props?.tabs.list.map((item: any, index: any) => {
            return (
              <TabButton
                key={index}
                active={props?.tabs.activeTab === item.key}
                onClick={() => {
                  props?.tabs.tabChangeHandler(item.key);
                  setData([]);
                  setCount(undefined);
                }}
              >
                {item.text} {item.notification && <span className="actions-pending-symbol">•</span>}
              </TabButton>
            );
          })}
        </div>
      )}

      {!props?.hideTableCTA && (
        <TableCTA
          searchPlaceholder={props?.searchPlaceholder}
          getParams={getParams}
          checkList={props?.checkList}
          resetParams={resetParams}
          params={props?.params}
          data={{ datasource: data, count: count, website: website }}
          searchKey={props?.searchKey}
          disableSearch={props?.disableSearch}
          type={props?.type}
          cta={props?.cta}
          filter={props?.filter}
          sort={props?.sort}
          editPermission={props?.editPermission}
        />
      )}

      {props?.type === 'providerOffers' || props?.type === 'providerProducts' ? (
        count && count > 0 ? (
          <TableComponent
            columns={props?.header}
            dataSource={data}
            count={count}
            currentPageHandler={currentPageHandler}
            getCheckList={props?.getCheckList}
            urlOffset={props?.urlOffset}
            urlLimit={props?.urlLimit}
            setRowKey="id"
            loading={tableLoading}
            hasPaging={props?.hasPaging}
          />
        ) : null
      ) : (
        <TableComponent
          columns={props?.header}
          dataSource={data}
          count={count}
          currentPageHandler={currentPageHandler}
          getCheckList={props?.getCheckList}
          urlOffset={props?.urlOffset}
          urlLimit={props?.urlLimit}
          setRowKey="id"
          loading={tableLoading}
          hasPaging={props?.hasPaging}
        />
      )}
    </div>
  );
};

export default EntityListing;
