import { Col, Drawer, Form, Input, Row, Image, Checkbox } from "antd";
import Loader from "../../../components/Loader/Loader";
import React, { useEffect, useState } from "react";
import api from "../../../api/api";
import { Button } from "../../../components/UI/Button/Button";
import Dragger from "antd/lib/upload/Dragger";
import apiEndpoints from "../../../api/apiEndPoints";
import { errorHandler, getAccessToken, replaceSpace } from "../../../utils/helper";
import { errorNotification, successNotification } from "../../../components/UI/Toast/Toast";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import './_claimsmanager.scss';
import Tooltip from "../../../components/UI/Tooltip/Tooltip";

interface Props {
  onClose: () => void;
  getDocuments: any;
  visible: boolean;
  data?: any;
  id: number;
}

const EditDocument: React.FC<Props> = ({ onClose, visible, data, id, getDocuments }) => {
  const [loading, setLoading] = useState(false),
    [documentLink, setDocumentLink] = useState<Array<Array<string>>>(id ? [] : [[data?.document_link]]),
    { claimid } = useParams(),
    navigate = useNavigate(),
    brokerName = useSelector((state: any) => state?.brokerconfig?.brandName),
    [form] = Form.useForm(),
    tooltip = (
      <Tooltip
        style={{ marginLeft: "16px" }}
        message={<div>Check this if the document is verified by the claims manager</div>}
      />
    ),
    props = {
      name: 'file',
      accept: '.png, .jpg, .jpeg, .pdf',
      action: apiEndpoints.uploadFile,
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
      },
      data: {
        folder_name: replaceSpace(brokerName, '_'),
      },
      onerror(e: any) {
        errorNotification('Error : Please try again');
      },
    },
    normFile = (e: any, key: number, edit: boolean = true) => {
      if (edit) {
        setDocumentLink([[e?.fileList?.[0]?.response?.result]])
        return [e?.fileList?.[0]?.response?.result]
      }
      else {
        const newLinks = e?.fileList.map((file: any) => file.response?.result);
        setDocumentLink((prev: any) => {
          return prev.map((item: any, index: number) => {
            if (index === key) {
              return newLinks
            } else {
              return item
            }
          })
        });
        return newLinks;
      }
    },
    addDocument = (add: any) => {
      const fields = form.getFieldsValue();
      const { documents } = fields;
      let lastDocument = documents.length - 1;
      if (documents[lastDocument]?.document_link) {
        add();
      } else {
        errorNotification('Add previous document first');
      }
    },
    removeDocument = (remove: any, name: number, index: number) => {
      setDocumentLink((prev: any) => {
        return prev?.filter((item: any, i: number) => i !== index)
      })
      remove(name);
    },
    onCreate = (redirect?: boolean) => {
      if (validateData()) {
        form.validateFields()
          .then((values) => {
            let payload = values?.documents?.map((item: any) => {
              return item?.document_link?.map((x: any) => {
                return {
                  claim: claimid,
                  document_type: id,
                  message: item?.message,
                  document_link: x,
                  verified: item?.verified,
                }
              })
            })?.flat()
            api.claims_management.postDocuments(payload)
              .then((res) => {
                if (redirect) {
                  navigate(`../treatment-details`);
                } else {
                  successNotification(res?.message);
                  onClose();
                  getDocuments();
                }
              })
              .catch((err) => {
                errorHandler(err?.response?.data);
              })
          })
          .catch((err) => {
            console.log(err);
          })
      }
    },
    onEdit = (redirect?: boolean) => {
      if (validateData()) {
        form.validateFields()
          .then((values) => {
            api.claims_management.editDocument(data.id, {
              message: values?.message,
              document_link: Array.isArray(values?.document_link) ? values?.document_link?.[0] : values?.document_link,
              verified: values?.verified,
            })
              .then((res) => {
                if (redirect) {
                  navigate(`../treatment-details`);
                } else {
                  successNotification(res?.message);
                  onClose();
                  getDocuments();
                }
              })
              .catch((err) => {
                errorHandler(err?.response?.data);
              })
          })
          .catch((err) => {
            console.log(err);
          })
      }
    },
    validateData = () => {
      const fields = form.getFieldsValue();
      const { documents } = fields;
      if (documents?.find((item: any) => !item?.document_link)) {
        errorNotification('Please upload all documents');
        return false;
      }
      return true;
    };

  useEffect(() => {
    // console.log(documentLink)
  }, [documentLink])

  return (
    <Drawer
      title={`${id ? 'Upload' : 'Edit'} Document`}
      closable={false}
      onClose={onClose}
      destroyOnClose
      visible={visible}
      width={420}
    >
      <Loader loading={loading} screen="claims">
        <Form form={form} initialValues={data}>
          {id
            ?
            <Form.List name="documents">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field, index) => (
                      <div className={form.getFieldValue('documents').length > index + 1 ? 'document-upload' : ''} key={index}>
                        <Form.Item
                          name={[index, 'document_link']}
                          label={<Row onClick={(e) => { e.preventDefault(); e.stopPropagation() }} justify="space-between" style={{ width: '380px' }}>
                            <Col>
                              <span className="required">Upload your document</span>
                            </Col>
                            <Col>
                              {fields.length > 1 && <span className="cta" onClick={() => removeDocument(remove, field.name, index)}><DeleteOutlined /></span>}
                            </Col>
                          </Row>}
                          valuePropName="document"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          getValueFromEvent={(e) => normFile(e, index, false)}
                          validateTrigger={['onBlur']}
                        >
                          <Dragger showUploadList={true} height={100} listType="picture" {...props} multiple={true}>
                            {documentLink?.length > index ?
                              <> {documentLink?.[index]?.length > 1 ? <div>Multiple files cannot be previewed</div> : documentLink?.[index]?.[0]?.endsWith('.pdf') ?
                                <div>This pdf file cannot be previewed.
                                  <br />
                                  To view, please download it.
                                  <br /><br />
                                  <div className="upload-replace">Click to replace</div>
                                </div> :
                                <>
                                  <Image preview={false} src={documentLink?.[index]?.[0]} alt="avatar" style={{ cursor: 'pointer', maxHeight: "250px", width: "auto" }} />
                                  <div className="upload-replace">Click to replace</div>
                                </>}
                              </>
                              :
                              "Click or drag file to this area to upload"
                            }
                          </Dragger>
                        </Form.Item>
                        <Form.Item
                          name={[index, "message"]}
                          label="Remarks"
                          labelCol={{ span: 24 }}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          labelCol={{ span: 24 }}
                          name={[index, "verified"]}
                          valuePropName="checked"
                        >
                          <Checkbox>
                            <span className="custom-label">
                              This document is verified
                            </span>
                            {tooltip}
                          </Checkbox>
                        </Form.Item>
                      </div>
                    ))}
                    <Col span={18} className="mt-24 mb-24">
                      <div className="cta" onClick={() => { addDocument(add) }}>
                        <PlusOutlined /> Add another Document
                      </div>
                    </Col>
                  </>
                )
              }}
            </Form.List>
            :
            <>
              <Form.Item
                name='document_link'
                label="Upload your document"
                valuePropName="pdf"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                getValueFromEvent={normFile}
                validateTrigger={['onBlur']}
                rules={[
                  {
                    required: true,
                    message: 'Please upload your document',
                  },
                ]}
              >
                <Dragger showUploadList={false} listType="picture" height={300} {...props} multiple={false} style={{ width: '372px', height: '300px' }}>
                  {documentLink &&
                    <>
                      {documentLink?.[0]?.[0]?.endsWith('.pdf') ?
                        <div>This pdf file cannot be previewed.
                          <br />
                          To view, please download it.
                          <br /><br />
                          <div className="upload-replace">Click to replace</div>
                        </div> :
                        <>
                          <Image preview={false} src={documentLink?.[0]?.[0]} alt="avatar" style={{ cursor: 'pointer', maxHeight: "250px", width: "auto" }} />
                          <div className="upload-replace">Click to replace</div>
                        </>}
                    </>
                  }
                </Dragger>
              </Form.Item>
              <Form.Item
                name="message"
                label="Remarks"
                labelCol={{ span: 24 }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 24 }}
                name="verified"
                valuePropName="checked"
              >
                <Checkbox>
                  <span className="custom-label">
                    This document is verified
                  </span>
                  {tooltip}
                </Checkbox>
              </Form.Item>
            </>
          }
        </Form>
        <Row justify="space-around" align="middle" gutter={[16, 16]} className="drawer-footer">
          <Col>
            <Button type="secondary" onClick={onClose}>Cancel</Button>
          </Col>
          <Col>
            <Button type="primary" onClick={() => id ? onCreate() : onEdit()}>Save</Button>
          </Col>
          <Col>
            <Button type="primary" onClick={() => id ? onCreate(true) : onEdit(true)}>Save and go to Treatment Details</Button>
          </Col>
        </Row>
      </Loader>
    </Drawer>
  )
}

export default EditDocument;
